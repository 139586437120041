<template>
  <div>

    <validation-observer
      ref="refFormObserver"
      tag="div"
      class="pt-1"
    >
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="{ validate, errors }"
            tag="div"
            vid="vidPaymentMethod"
            rules="required"
            :name="$t('errorNoti.columns.type')"
          >
            <b-form-group
              class="mb-0"
              :state="errors[0] ? false : null"
            >
              <template #label>
                <div class="font-weight-bold">
                  {{ $t('errorNoti.columns.type') }}
                  <span class="text-danger">(*)</span>
                </div>
              </template>
              <v-select
                v-model="errorNotiData.type"
                style="font-size: 1rem;"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :placeholder="$t('errorNoti.phSelectNotiType')"
                :options="TYPE_OPTIONS"
                :clearable="false"
                :reduce="val => val.value"
                @close="validate"
              >
                <template #option="data">
                  <span>
                    {{ $t(data.label) }}
                  </span>
                </template>
                <template #selected-option="data">
                  <span>
                    {{ $t(data.label) }}
                  </span>
                </template>
              </v-select>
            </b-form-group>
            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
          </validation-provider>
        </b-col>

        <b-col
          v-if="errorNotiData.type === 'CQT'"
          cols="12"
          md="4"
        >
          <b-form-group>
            <template #label>
              <div class="font-weight-bold">
                {{ $t('errorNoti.errorNotiNo') }}
                <span class="text-danger">(*)</span>
              </div>
            </template>
            <validation-provider
              #default="{ errors }"
              tag="div"
              vid="vidCompany"
              rules="required"
              :name="$t('errorNoti.errorNotiNo')"
            >
              <b-form-input
                v-model.trim="errorNotiData.errorCode"
                maxlength="255"
                :state="errors[0] ? false : null"
              />
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          v-if="errorNotiData.type === 'CQT'"
          cols="12"
          md="4"
        >
          <validation-provider
            #default="{ errors }"
            name="errorNotiDate"
            rules="required|dateFormat"
          >
            <b-form-group
              label-for="errorNotiDate"
            >
              <template #label>
                <div class="font-weight-bold">
                  {{ $t('errorNoti.errorNotiDate') }}
                  <span class="text-danger">(*)</span>
                </div>
              </template>
              <b-form-datepicker
                v-model="errorNotiData.errorDate"
                name="errorNotiDate"
                class="form-control"
                :state="errors[0] ? false : null"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
                :min="new Date(1920, 0, 1)"
                :max="new Date()"
                show-decade-nav
                hide-header
                locale="vi"
                no-flip
              />
            </b-form-group>
            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>

    <b-table
      style="margin-bottom: 0; padding: 1em;"
      responsive
      show-empty
      primary-key="id"
      table-class="table-invoice-edit-gs border-bottom"
      :items="errorNotiData.invoices"
      :fields="tableColumnsAdd"
      :empty-text="$t('noRecordFund')"
    >
      <!-- ANCHOR Table Header -->
      <template
        v-for="column in tableColumnsAdd"
        v-slot:[`head(${column.key})`]="{ label }"
      >
        <span
          :key="column.label"
          class="text-dark text-nowrap text-center"
        >
          {{ $t(`errorNoti.gsColumns.${label}`) }}
        </span>
      </template>

      <!-- ANCHOR Column orderNo -->
      <template #cell(orderNo)="{ index }">
        <div
          class="w-100 text-center pt-50"
        >
          {{ index + 1 }}
        </div>
      </template>

      <template #cell(action)="data">
        <div>
          <feather-icon
            icon="TrashIcon"
            size="18"
            style="cursor: pointer"
            @click="deleteRow(data.item)"
          />
        </div>
      </template>

    </b-table>

    <b-button
      @click="showPopupInvoiceList"
    >
      <!-- TODO: i18n -->
      Select invoices
    </b-button>

    <div class="d-flex justify-content-center justify-content-xl-end align-items-center flex-grow-1 flex-wrap">
      <b-button
        variant="primary"
        class="ml-1 mt-50"
      >
        {{ $t('errorNoti.btn.preview') }}
      </b-button>

      <b-button
        variant="primary"
        class="ml-1 mt-50"
        @click="SaveErrorNoti"
      >
        {{ $t('errorNoti.btn.save') }}
      </b-button>

      <b-button
        variant="primary"
        class="ml-1 mt-50"
      >
        {{ $t('errorNoti.btn.saveAndSend') }}
      </b-button>
      <b-button
        variant="primary"
        class="ml-1 mt-50"
      >
        {{ $t('invoice.btn.sign') }}
      </b-button>

      <b-button
        variant="primary"
        class="ml-1 mt-50"
      >
        {{ $t('errorNoti.btn.cancel') }}
      </b-button>
    </div>

    <!-- TODO: i18n for popupTitle -->
    <InvoicePopupList
      :fixed-invoice-types="[
        INVOICE_TYPE_VALUES.original,
        INVOICE_TYPE_VALUES.adjustment,
        INVOICE_TYPE_VALUES.replacement,
      ]"
      :fixed-invoice-status="[
        INVOICE_STATUS_VALUES.released
      ]"
      popup-title="Chọn hoá đơn Đã phát hành"
      @selectInvoices="onAddInvoices"
    />
  </div>
</template>

<script>
import {
  BButton,
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormInvalidFeedback,
  BFormInput,
  BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  ref,
  onUnmounted,
} from '@vue/composition-api'

import { getUserData } from '@/api/auth/utils'
import { useRouter } from '@/@core/utils/utils'
import store from '@/store'
import {
  INVOICE_TYPE_VALUES,
  INVOICE_STATUS_VALUES,
} from '@/constants/invoice'
import route from '@/router'
import {
  ERROR_NOTI_APP_STORE_MODULE_NAME as ERROR_NOTI_STORE,
  ERROR_NOTI_TYPE_OPTIONS as TYPE_OPTIONS,
} from '@/constants/errorNoti'

import errorNotiStoreModule from '../errorNotiStoreModule'
import InvoicePopupList from '../../invoices/invoice-popup/InvoicePopupList.vue'
import {
  showPopupInvoiceList,
} from '../../invoices/invoice-popup/useInvoicePopupList'
import useErrorNotiAddHandle from '../error-noti-add/useErrorNotiAddHandle'
import useErrorNotiHandle from '../error-noti-list/useErrorNotiHandle'

export default {
  components: {
    InvoicePopupList,

    ValidationObserver,
    ValidationProvider,

    BButton,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    BFormInput,
    BFormDatepicker,
    vSelect,
  },

  setup() {
    // Register module
    if (!store.hasModule(ERROR_NOTI_STORE)) {
      store.registerModule(ERROR_NOTI_STORE, errorNotiStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ERROR_NOTI_STORE)) {
        store.unregisterModule(ERROR_NOTI_STORE)
      }
    })

    const loading = ref(true)
    const selectedAdd = ref([])
    const errorNotiData = ref({
      creator: getUserData().employeeData.id,
      type: '',
      errorCode: '',
      errorDate: '',
      invoices: [],
    })
    const fetchInvoiceData = () => store.dispatch('app-error-noti/getErrorNotiById', { id: route.currentRoute.params.id })
      .then(response => {
        errorNotiData.value = response?.data
      })
      .catch(error => {
        if (error) {
          errorNotiData.value = undefined
        }
      })
      .finally(() => {
        loading.value = false
      })

    fetchInvoiceData()

    function onAddInvoices({
      selectedInvoices,
    }) {
      selectedAdd.value = selectedInvoices
    }

    const deleteRow = data => {
      selectedAdd.value = selectedAdd.value.filter(el => el !== data)
    }

    const {
      tableColumnsAdd,
      ErrorNotificationType,
    } = useErrorNotiAddHandle()

    const { router } = useRouter()
    const {
      createErrorNoti,
    } = useErrorNotiHandle()

    const SaveErrorNoti = () => {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < selectedAdd.value.length; i++) {
        const obj = selectedAdd.value[i]
        const newObj = {}
        newObj.id = obj.id
        newObj.cancelReason = obj.cancelReason
        newObj.errorNotificationType = obj.invoiceType
        for (const key in ErrorNotificationType) {
          if (newObj.errorNotificationType === key) newObj.errorNotificationType = ErrorNotificationType[key]
        }
        if (newObj.cancelReason == null) newObj.cancelReason = 'string'
        selectedAdd.value[i] = newObj
      }

      if (errorNotiData.value.type === 'NNT') {
        delete errorNotiData.value.errorCode
        delete errorNotiData.value.errorDate
      }

      errorNotiData.value.invoices = selectedAdd.value
      createErrorNoti(errorNotiData.value)
        .then(() => {
          router.push({ name: 'apps-error-noti-list' })
        })
    }

    return {
      INVOICE_TYPE_VALUES,
      INVOICE_STATUS_VALUES,
      TYPE_OPTIONS,

      showPopupInvoiceList,
      onAddInvoices,

      tableColumnsAdd,
      ErrorNotificationType,
      selectedAdd,
      deleteRow,
      SaveErrorNoti,
      createErrorNoti,

      errorNotiData,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
